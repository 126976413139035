var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "event-board", staticStyle: { "text-align": "center" } },
      [
        _c(
          "div",
          {
            staticClass: "balloon-01",
            staticStyle: {
              position: "absolute",
              width: "150px",
              height: "64px",
              left: "765px",
              top: "585px",
            },
          },
          [
            _vm.checkDateEnd(_vm.endDateStep1)
              ? _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/day-balloon-01.png"),
                    alt: "0208 before",
                  },
                })
              : _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/endDay-balloon-01.png"),
                    alt: "0208 after",
                  },
                }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "balloon-02",
            staticStyle: {
              position: "absolute",
              width: "150px",
              height: "64px",
              left: "89px",
              top: "746px",
            },
          },
          [
            _vm.checkDateEnd(_vm.endDateStep2)
              ? _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/day-balloon-02.png"),
                    alt: "0208 before",
                  },
                })
              : _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/endDay-balloon-02.png"),
                    alt: "0208 after",
                  },
                }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "balloon-03",
            staticStyle: {
              position: "absolute",
              width: "150px",
              height: "64px",
              left: "750px",
              top: "832px",
            },
          },
          [
            _vm.checkDateEnd(_vm.endDateStep3)
              ? _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/day-balloon-03.png"),
                    alt: "0208 before",
                  },
                })
              : _c("img", {
                  staticStyle: { position: "absolute", left: "0", top: "0" },
                  attrs: {
                    src: require("@/assets/images/events/event240113/endDay-balloon-03.png"),
                    alt: "0208 after",
                  },
                }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race1",
            staticStyle: { position: "absolute", left: "124px", top: "600px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "1회차 1,000Kcal" },
              },
              [
                _vm.historyCountStep1 >= 1
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-01.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep1)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-01.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(1)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-01.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race2",
            staticStyle: { position: "absolute", left: "348px", top: "600px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "2회차 2,000Kcal" },
              },
              [
                _vm.historyCountStep1 >= 2
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-02.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep1)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-02.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(1)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-02.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race3",
            staticStyle: { position: "absolute", left: "574px", top: "600px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "3회차 3,000Kcal" },
              },
              [
                _vm.historyCountStep1 >= 3
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-03.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep1)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-03.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(1)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-03.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race4",
            staticStyle: { position: "absolute", left: "784px", top: "655px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "4회차 4,000Kcal" },
              },
              [
                _vm.historyCountStep1 >= 4
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-04.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep1)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-04.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(1)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-04.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race5",
            staticStyle: { position: "absolute", left: "545px", top: "741px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "5회차 5,000Kcal" },
              },
              [
                _vm.historyCountStep2 >= 1
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-05.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep2)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-05.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(2)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-05.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race6",
            staticStyle: { position: "absolute", left: "277px", top: "741px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "6회차 6,000Kcal" },
              },
              [
                _vm.historyCountStep2 >= 2
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-06.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep2)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-06.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(2)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-06.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race7",
            staticStyle: { position: "absolute", left: "34px", top: "803px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "7회차 7,000Kcal" },
              },
              [
                _vm.historyCountStep2 >= 3
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-07.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep2)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-07.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(2)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-07.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race8",
            staticStyle: { position: "absolute", left: "219px", top: "893px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "8회차 8,000Kcal" },
              },
              [
                _vm.historyCountStep3 >= 1
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-08.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep3)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-08.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(3)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-08.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race9",
            staticStyle: { position: "absolute", left: "464px", top: "893px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "9회차 9,000Kcal" },
              },
              [
                _vm.historyCountStep3 >= 2
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-09.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep3)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-09.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(3)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-09.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "race10",
            staticStyle: { position: "absolute", left: "720px", top: "893px" },
          },
          [
            _c(
              "a",
              {
                staticStyle: {
                  width: "147px",
                  height: "98px",
                  position: "relative",
                },
                attrs: { title: "10회차 10,000Kcal" },
              },
              [
                _vm.historyCountStep3 >= 3
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/completion-10.png"),
                        alt: "충전완료",
                      },
                    })
                  : _vm.checkDateEnd(_vm.endDateStep3)
                  ? _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/before-10.png"),
                        alt: "충전전",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.createEventHistory(3)
                        },
                      },
                    })
                  : _c("img", {
                      staticStyle: {
                        position: "absolute",
                        left: "0",
                        top: "0",
                      },
                      attrs: {
                        src: require("@/assets/images/events/event240113/fail-09.png"),
                        alt: "충전실패",
                      },
                    }),
              ]
            ),
          ]
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/events/event240113/bg-charging-race.jpg"),
            alt: "지식 칼로리 충전 레이스",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }