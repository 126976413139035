<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <!-- 날짜 말풍선 -->
      <div
        class="balloon-01"
        style="position: absolute; width:150px; height:64px; left:765px; top:585px;"
      >
        <img
          v-if="checkDateEnd(endDateStep1)"
          src="@/assets/images/events/event240113/day-balloon-01.png"
          alt="0208 before"
          style="position: absolute; left: 0; top: 0;"
        >
        <img
          v-else
          src="@/assets/images/events/event240113/endDay-balloon-01.png"
          alt="0208 after"
          style="position: absolute; left: 0; top: 0;"
        >
      </div>
      <div
        class="balloon-02"
        style="position: absolute; width:150px; height:64px; left: 89px; top:746px; "
      >
        <img
          v-if="checkDateEnd(endDateStep2)"
          src="@/assets/images/events/event240113/day-balloon-02.png"
          alt="0208 before"
          style="position: absolute; left: 0; top: 0;"
        >
        <img
          v-else
          src="@/assets/images/events/event240113/endDay-balloon-02.png"
          alt="0208 after"
          style="position: absolute; left: 0; top: 0;"
        >
      </div>
      <div
        class="balloon-03"
        style="position: absolute; width:150px; height:64px; left:750px; top:832px;"
      >
        <img
          v-if="checkDateEnd(endDateStep3)"
          src="@/assets/images/events/event240113/day-balloon-03.png"
          alt="0208 before"
          style="position: absolute; left: 0; top: 0;"
        >
        <img
          v-else
          src="@/assets/images/events/event240113/endDay-balloon-03.png"
          alt="0208 after"
          style="position: absolute; left: 0; top: 0;"
        >
      </div>

      <!-- 칼로리 레이스 -->
      <div
        class="race1"
        style="position: absolute; left:124px; top:600px"
      >
        <a
          title="1회차 1,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep1 >= 1"
            src="@/assets/images/events/event240113/completion-01.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep1)"
            src="@/assets/images/events/event240113/before-01.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(1)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-01.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race2"
        style="position: absolute; left:348px; top:600px"
      >
        <a
          title="2회차 2,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep1 >= 2"
            src="@/assets/images/events/event240113/completion-02.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep1)"
            src="@/assets/images/events/event240113/before-02.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(1)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-02.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race3"
        style="position: absolute; left:574px; top:600px"
      >
        <a
          title="3회차 3,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep1 >= 3"
            src="@/assets/images/events/event240113/completion-03.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep1)"
            src="@/assets/images/events/event240113/before-03.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(1)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-03.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race4"
        style="position: absolute; left:784px; top:655px"
      >
        <a
          title="4회차 4,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep1 >= 4"
            src="@/assets/images/events/event240113/completion-04.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep1)"
            src="@/assets/images/events/event240113/before-04.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(1)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-04.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race5"
        style="position: absolute; left:545px; top:741px"
      >
        <a
          title="5회차 5,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep2 >= 1"
            src="@/assets/images/events/event240113/completion-05.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep2)"
            src="@/assets/images/events/event240113/before-05.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(2)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-05.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race6"
        style="position: absolute; left:277px; top:741px"
      >
        <a
          title="6회차 6,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep2 >= 2"
            src="@/assets/images/events/event240113/completion-06.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep2)"
            src="@/assets/images/events/event240113/before-06.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(2)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-06.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race7"
        style="position: absolute; left:34px; top:803px"
      >
        <a
          title="7회차 7,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep2 >= 3"
            src="@/assets/images/events/event240113/completion-07.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep2)"
            src="@/assets/images/events/event240113/before-07.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(2)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-07.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race8"
        style="position: absolute; left:219px; top:893px"
      >
        <a
          title="8회차 8,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep3 >= 1"
            src="@/assets/images/events/event240113/completion-08.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep3)"
            src="@/assets/images/events/event240113/before-08.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(3)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-08.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race9"
        style="position: absolute; left:464px; top:893px"
      >
        <a
          title="9회차 9,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep3 >= 2"
            src="@/assets/images/events/event240113/completion-09.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep3)"
            src="@/assets/images/events/event240113/before-09.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(3)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-09.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <div
        class="race10"
        style="position: absolute; left:720px; top:893px"
      >
        <a
          title="10회차 10,000Kcal"
          style="width: 147px; height: 98px; position:relative;"
        >
          <img
            v-if="historyCountStep3 >= 3"
            src="@/assets/images/events/event240113/completion-10.png"
            alt="충전완료"
            style="position: absolute; left: 0; top: 0;"
          >
          <img
            v-else-if="checkDateEnd(endDateStep3)"
            src="@/assets/images/events/event240113/before-10.png"
            alt="충전전"
            style="position: absolute; left: 0; top: 0;"
            @click.stop="createEventHistory(3)"
          >
          <img
            v-else
            src="@/assets/images/events/event240113/fail-09.png"
            alt="충전실패"
            style="position: absolute; left: 0; top: 0;"
          >
        </a>
      </div>
      <!-- 배경 -->
      <img
        src="@/assets/images/events/event240113/bg-charging-race.jpg"
        alt="지식 칼로리 충전 레이스"
      >
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { numberFormatter } from '@/libs/utils/filters'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      completeEvent: false,
      historyCount: 0,
      isProcessing: false,
      startDateStep1: '2024-02-01',
      startDateStep2: '2024-02-09',
      startDateStep3: '2024-02-22',
      endDateStep1: '2024-02-08',
      endDateStep2: '2024-02-21',
      endDateStep3: '2024-02-29',
      historyCountStep1: 0,
      historyCountStep2: 0,
      historyCountStep3: 0,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName() {
      if (this.userName) this.fetchEventHistory()
      if (this.userName) this.fetchEventComplete()
    },
  },
  created() {
    if (this.userName) this.fetchEventHistory()
    if (this.userName) this.fetchEventComplete()
  },
  methods: {
    checkDateEnd(toDate) {
      return toDate >= this.today
    },
    fetchEventHistory() {
      axios.get(`/fu/event/hiscount/${this.eventId}`)
        .then(rs => {
          this.historyCount = rs.data
        }).catch(() => {})

      if (this.today >= this.startDateStep1) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep1}/${this.endDateStep1}`)
          .then(rs => {
            this.historyCountStep1 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep2) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep2}/${this.endDateStep2}`)
          .then(rs => {
            this.historyCountStep2 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep3) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep3}/${this.endDateStep3}`)
          .then(rs => {
            this.historyCountStep3 = rs.data
          }).catch(() => {})
      }
    },
    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`)
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    createEventHistory(step) {
      if (this.userName) {
        if (this.isProcessing) return

        if (this.completeEvent) {
          this.$parent.showAlertDialog('출석체크에 참여한 컨텐츠 입니다.')
          return
        }
        if (this.historyCount >= 10) {
          this.$parent.showAlertDialog('참여 가능한 출석체크를 모두 완료하셨습니다.')
          return
        }
        if (step === 1) {
          if (this.historyCountStep1 > 4) {
            this.$parent.showAlertDialog('1회차 ~ 4회차에 참여 가능한 출석체크를 모두 완료하셨습니다.')
            return
          } if (this.today < this.startDateStep1) {
            this.$parent.showAlertDialog('1회차 ~ 4회차는 아직 참여하실 수 없습니다. (2월 1일부터 참여 가능)')
            return
          } if (this.today > this.endDateStep1) {
            this.$parent.showAlertDialog('1회차 ~ 4회차는 참여기간이 종료되었습니다.')
            return
          }
        }
        if (step === 2) {
          if (this.historyCountStep2 > 3) {
            this.$parent.showAlertDialog('5회차 ~ 7회차에 참여 가능한 출석체크를 모두 완료하셨습니다.')
            return
          } if (this.today < this.startDateStep2) {
            this.$parent.showAlertDialog('5회차 ~ 7회차는 아직 참여하실 수 없습니다. (2월 9일부터 참여 가능)')
            return
          } if (this.today > this.endDateStep2) {
            this.$parent.showAlertDialog('5회차 ~ 7회차는 참여기간이 종료되었습니다.')
            return
          }
        }
        if (step === 3) {
          if (this.historyCountStep3 > 3) {
            this.$parent.showAlertDialog('8회차 ~ 10회차에 참여 가능한 출석체크를 모두 완료하셨습니다.')
            return
          } if (this.today < this.startDateStep3) {
            this.$parent.showAlertDialog('8회차 ~ 10회차는 아직 참여하실 수 없습니다. (2월 22일부터 참여 가능)')
            return
          } if (this.today > this.endDateStep3) {
            this.$parent.showAlertDialog('8회차 ~ 10회차는 참여기간이 종료되었습니다.')
            return
          }
        }

        // processing on
        this.isProcessing = true

        this.$parent.showConfirmDialog('출석 체크 하시겠습니까?', (result) => {
          if (result) {
            axios.post('/fu/event/history', {
              EventId: this.eventId,
              ContentId: this.contentId,
            })
              .then(rs => {
                setStatistics(121, this.eventId)

                if (rs.data && rs.data.pointBalance) changeUserPoint(rs.data.pointBalance)

                this.fetchEventHistory()

                this.$parent.showAlertDialog(`메디컬트렌드 지식 칼로리 충전 이벤트 참여 완료되어 ${numberFormatter(rs.data.awardPoint)}포인트가 지급되었습니다.`, () => {
                  this.isProcessing = false
                  this.completeEvent = true
                })
              })
              .catch(() => {
                this.isProcessing = false

                this.$parent.showAlertDialog('메디컬트렌드 지식 칼로리 충전 이벤트 처리 중 오류가 발생했습니다.')
              })
          } else {
            this.isProcessing = false
          }
        })
      } else {
        this.isProcessing = false

        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
  body {
      margin: 0;
      padding: 0;
  }
  img {
      display: block;
      margin: 0 auto;
  }
  area:hover {
      cursor: pointer;
  }
  .event-board {
      width: 1000px;
      margin: 0 auto;
      position: relative;
  }
  .event-checked img {
      display: none;
      position: absolute;
  }
</style>
